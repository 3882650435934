import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import CDNImageCommand from "./CDNImageCommand";
import { toWidget } from "@ckeditor/ckeditor5-widget/src/utils";

export default class CDNImageController extends Plugin {
    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( "cdnImage", new CDNImageCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        /*
         * <cdnImage>
         */
        schema.register( "cdnImage", {
            isObject: true,
            allowWhere: "$block",
            allowAttributes: [ "src" ],
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        /*
         * <cdnImage>
         */
        conversion.for("upcast").elementToElement( {
            model: "cdnImage",
            view: {
                name: "cdnImage",
            }
        } );
        conversion.for( "dataDowncast" ).elementToElement( {
            model: "cdnImage",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createEmptyElement( "img", {
                    src: modelElement.getAttribute( "src" ),
                } );
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "cdnImage",
            view: ( modelElement, { writer: viewWriter } ) => {
                const img = viewWriter.createContainerElement( "img", {
                    src: modelElement.getAttribute( "src" ),
                } );

                return toWidget( img, viewWriter );
            }
        } );
    }
}