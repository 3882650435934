import Command from "@ckeditor/ckeditor5-core/src/command";

export default class CDNImageCommand extends Command {
    execute() {
        const editor = this.editor;

        const onImageSelected = (imageURL) => {
            editor.model.change(writer => {
                const imageElement = writer.createElement("cdnImage", { src: imageURL });
                editor.model.insertContent( imageElement, editor.model.document.selection );
            })
        }

        const openCallback = editor.config.get("cdnImage.callbackName")

        if (typeof window[openCallback] === "function") {
            window[openCallback](onImageSelected)
        } else {
            console.log("cdnImage.callbackName variable not set!")
        }
    }
}