import Command from "@ckeditor/ckeditor5-core/src/command";

export default class DocumentBoxCommand extends Command {
    /**
     * If documentBoxContainer is given, then we perform an update on the documents: don't recreate existing ones and delete those that were previously in the documentBoxContainer, but no longer in the callback documents
     * @param {*} documentBoxContainer
     */
    execute(documentBoxContainer) {
        const editor = this.editor;
        const model = editor.model;

        const onDocumentsSelected = (documents) => {
            model.change((writer) => {
                if (documentBoxContainer === undefined) {
                    this.createDocumentBox(writer, documents);
                } else {
                    this.updateDocumentBox(writer, documentBoxContainer, documents);
                }
            });
        };

        const documentBoxCallback = editor.config.get("documentBox.callbackName");

        if (typeof window[documentBoxCallback] === "function") {
            window[documentBoxCallback](this.getAddedDocuments(documentBoxContainer || []), onDocumentsSelected);
        } else {
            console.log("documentBox.callbackName variable not set!");
        }
    }

    createDocumentBox(writer, documents) {
        if (!this.isValidDocumentFound(documents)) {
            return;
        }

        const documentBoxContainer = writer.createElement( "documentBoxContainer" );
        const documentBoxDocumentsContainer = writer.createElement( "documentBoxDocumentsContainer" );

        for (let index = 0; index < documents.length; index++) {
            const documentAttributes = {
                "data-id": documents[index].id,
                "data-title": documents[index].caption,
                "src": documents[index].url,
            };
            const documentElement = writer.createElement("documentBoxDocument", documentAttributes);
            writer.append( documentElement, documentBoxDocumentsContainer );
        }

        const documentBoxButton = writer.createElement( "documentBoxButton" );
        writer.append( documentBoxDocumentsContainer, documentBoxContainer );
        writer.append( documentBoxButton, documentBoxContainer );

        this.editor.model.insertContent( documentBoxContainer, this.editor.model.document.selection );
    }

    updateDocumentBox(writer, documentBoxDocumentsContainer, documents) {
        const addedDocuments = this.getAddedDocuments(documentBoxDocumentsContainer);

        // Add new documents (and ignore documents that were already added)
        for (let index = 0; index < documents.length; index++) {
            if (addedDocuments.find(addedDocument => addedDocument.id === documents[index].id)) {
                continue;
            }

            const documentAttributes = {
                "data-id": documents[index].id,
                "data-title": documents[index].caption,
                "src": documents[index].url,
            };

            const documentElement = writer.createElement("documentBoxDocument", documentAttributes);
            writer.append( documentElement, documentBoxDocumentsContainer );
        }

        // Remove documents (look for those that were already added but are not in the new documents)
        const addedIds = addedDocuments.map(addedDocument => addedDocument.id);
        const newIds = documents.map(document => document.id);
        const removedIds = addedIds.filter(addedId => !newIds.includes(addedId));

        const children = documentBoxDocumentsContainer.getChildren();
        let result = children.next();

        const elementsToRemove = [];
        while (!result.done) {
            if (result.value.name === "documentBoxDocument" && removedIds.includes(result.value.getAttribute("data-id"))) {
                elementsToRemove.push(result.value);
            }
            result = children.next();
        }

        elementsToRemove.forEach(element => writer.remove(element));
    }

    isValidDocumentFound(documents) {
        return !!documents.find((document) => {
            return typeof document.url === "string" && document.url.length > 0;
        });
    }

    getAddedDocuments(documentBoxDocumentsContainer) {
        if (documentBoxDocumentsContainer.length === 0) {
            return;
        }

        const addedDocumentURLs = [];

        const documentBoxContainerChildren = documentBoxDocumentsContainer.getChildren();
        let result = documentBoxContainerChildren.next();
        while (!result.done) {
            if (result.value.name === "documentBoxDocument") {
                addedDocumentURLs.push({
                    id: result.value.getAttribute("data-id"),
                    title: result.value.getAttribute("data-title"),
                    url: result.value.getAttribute("src"),
                });
            }
            result = documentBoxContainerChildren.next();
        }

        return addedDocumentURLs;
    }
}
