import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import InfoImageCommand from "./InfoImageCommand";
import { toWidget, toWidgetEditable } from "@ckeditor/ckeditor5-widget/src/utils";

export default class InfoImageController extends Plugin {
    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( "infoImage", new InfoImageCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        /*
         * <infoImageContainer>
         */
        schema.register( "infoImageContainer", {
            isObject: true,
            allowWhere: "$block"
        } );

        this.editor.model.schema.addAttributeCheck( context => {
            if (context.endsWith( "infoImageContainer" )) {
                return true;
            }
        } );

        /*
         * <infoImage>
         */
        schema.register( "infoImage", {
            isObject: true,
            allowIn: "infoImageContainer",
        });

        schema.addAttributeCheck( context => {
            if (context.endsWith( "infoImage" )) {
                return true;
            }
        } );

        /*
         * <infoCaption>
         */
        schema.register( "infoCaption", {
            isLimit: true,
            allowIn: "infoImageContainer",
            allowContentOf: "$block"
        } );

        schema.addAttributeCheck( context => {
            if (context.endsWith( "infoCaption" )) {
                return true;
            }
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        /*
         * <infoImageContainer>
         */
        conversion.for("upcast").elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "infoImageContainer", { class: viewElement.getAttribute( "class" ) } );
            },
            view: {
                name: "div",
            }
        } );

        conversion.for( "dataDowncast" ).elementToElement( {
            model: "infoImageContainer",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createContainerElement( "div", {
                    class: modelElement.getAttribute( "class" ),
                } );
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "infoImageContainer",
            view: ( modelElement, { writer: viewWriter } ) => {
                const div = viewWriter.createContainerElement( "div", {
                    class: modelElement.getAttribute( "class" ),
                } );

                return toWidget( div, viewWriter );
            }
        } );

        /**
         * <infoImage>
         */
        conversion.for( "upcast" ).elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "infoImage", {
                    fileName: viewElement.getAttribute( "filename" ),
                    "data-asset-url": viewElement.getAttribute( "data-asset-url" ),
                } );
            },
            view: {
                name: "infoimage",
            },
        } );

        conversion.for( "dataDowncast" ).elementToElement( {
            model: "infoImage",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createEmptyElement( "infoImage", {
                    fileName: modelElement.getAttribute( "fileName" ),
                    "data-asset-url": modelElement.getAttribute( "data-asset-url" ),
                } );
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "infoImage",
            view: ( modelElement, { writer: viewWriter } ) => {
                const img = viewWriter.createEditableElement( "img", {
                    src: modelElement.getAttribute( "data-asset-url" ),
                } );

                return img;
            }
        } );

        /**
         * <infoCaption>
         */
        conversion.for( "upcast" ).elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "infoCaption", { class: viewElement.getAttribute( "class" ) } );
            },
            view: {
                name: "p",
            }
        } );
        conversion.for( "dataDowncast" ).elementToElement( {
            model: "infoCaption",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createEditableElement( "p", {
                    class: modelElement.getAttribute( "class" ),
                } );
            }
        } );
        conversion.for( "editingDowncast" ).elementToElement( {
            model: "infoCaption",
            view: ( modelElement, { writer: viewWriter } ) => {
                const p = viewWriter.createEditableElement( "p", {
                    class: modelElement.getAttribute( "class" ),
                } );
                return toWidgetEditable( p, viewWriter );
            }
        } );

    }
}