import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import { toWidget } from "@ckeditor/ckeditor5-widget/src/utils";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import DocumentBoxCommand from "./DocumentBoxCommand";
import { Constants } from "../constants";

export default class DocumentBoxController extends Plugin {
    static get requires() {
        return [ Widget ];
    }

    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( "documentBox", new DocumentBoxCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        /*
         * <documentBoxContainer>
         */
        schema.register( "documentBoxContainer", {
            isObject: true,
            allowWhere: "$block"
        });

        /*
         * <documentBoxDocumentsContainer>
         */
        schema.register( "documentBoxDocumentsContainer", {
            isObject: true,
            allowIn: "documentBoxContainer"
        });

        /*
         * <documentBoxDocument>
         */
        schema.register( "documentBoxDocument", {
            isObject: true,
            allowIn: "documentBoxDocumentsContainer",
        });

        schema.addAttributeCheck( context => {
            if (context.endsWith( "documentBoxDocument" )) {
                return true;
            }
        } );

        /*
         * <documentBoxButton>
         */
        schema.register( "documentBoxButton", {
            isObject: true,
            allowIn: "documentBoxContainer",
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        const editor = this.editor;

        /**
         * <documentBoxContainer>
         */
        conversion.for("upcast").elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "documentBoxContainer" );
            },
            view: {
                name: "documentBoxContainer",
            }
        } );

        conversion.for("dataDowncast").elementToElement( {
            model: "documentBoxContainer",
            view: {
                name: "documentBoxContainer",
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "documentBoxContainer",
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( "div", { class: `${Constants.selectorPrefix}-documentbox-container` } );

                return toWidget( section, viewWriter );
            }
        } );

        /**
         * <documentBoxDocumentsContainer>
         */
        conversion.for("upcast").elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "documentBoxDocumentsContainer" );
            },
            view: {
                name: "documentBoxDocumentsContainer",
            }
        } );

        conversion.for("dataDowncast").elementToElement( {
            model: "documentBoxDocumentsContainer",
            view: {
                name: "documentBoxDocumentsContainer",
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "documentBoxDocumentsContainer",
            view: {
                name: "div",
                classes: `${Constants.selectorPrefix}-documentbox-documents-container`
            }
        } );

        /**
         * <documentBoxDocument>
         */
        conversion.for( "upcast" ).elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "documentBoxDocument", {
                    "data-id": viewElement.getAttribute( "data-id" ),
                    "data-title": viewElement.getAttribute( "data-title" ),
                    src: viewElement.getAttribute( "src" ),
                    "data-source": viewElement.getAttribute( "data-source" ),
                    "data-asset-url": viewElement.getAttribute( "data-asset-url" ),
                } );
            },
            view: {
                name: "documentBoxDocument",
            },
        } );

        conversion.for( "dataDowncast" ).elementToElement( {
            model: "documentBoxDocument",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createEmptyElement( "documentBoxDocument", {
                    "data-id": modelElement.getAttribute( "data-id" ),
                    "data-title": modelElement.getAttribute( "data-title" ),
                    src: modelElement.getAttribute( "src" ),
                } );
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "documentBoxDocument",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createRawElement( "p", { class: "document-box-title" }, function( domElement ) {
                    domElement.innerHTML = modelElement.getAttribute( "data-title" );
                } );
            }
        } );

        /*
         * <documentBoxButton>
         */
        conversion.for( "upcast" ).elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "documentBoxButton" );
            },
            view: {
                name: "documentBoxbutton",
            }
        } );

        conversion.for( "dataDowncast" ).elementToElement( {
            model: "documentBoxButton",
            view: {
                name: "documentBoxButton",
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "documentBoxButton",
            view: ( modelElement, { writer: viewWriter } ) => {
                const buttonUIElement = viewWriter.createUIElement( "button", { type: "button" }, function( domDocument ) {
                    const domElement = this.toDomElement( domDocument );

                    const documentBoxDocumentsContainer = modelElement.previousSibling;

                    domElement.addEventListener("click", () => editor.execute("documentBox", documentBoxDocumentsContainer));
                    domElement.innerHTML = editor.config.get("documentBox.editButtonLabel") || Constants.documentBox.fallbackEditButtonLabel;

                    return domElement;
                } );
                return buttonUIElement;
            },
        } );
    }
}