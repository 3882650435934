export const Constants = {
    selectorPrefix: "ck-bf",
    gallery: {
        fallbackToolbarLabel: "Galéria",
        fallbackEditButtonLabel: "Galéria szerkesztése",
    },
    productList: {
        fallbackToolbarLabel: "Termékek",
        fallbackEditButtonLabel: "Termékek szerkesztése",
    },
    documentBox: {
        fallbackToolbarLabel: "Dokumentumok",
        fallbackEditButtonLabel: "Dokumentumok szerkesztése",
    },
    embeddedPost: {
        fallbackToolbarLabel: "Cikk",
        fallbackEditButtonLabel: "Cikk szerkesztése",
        fallbackSelectedIDLabel: "Kiválasztott cikk",
    },
    cndImage: {
        fallbackToolbarLabel: "Kép",
    },
}