import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import { toWidget } from "@ckeditor/ckeditor5-widget/src/utils";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import ProductListCommand from "./ProductListCommand";
import { Constants } from "../constants";

export default class ProductListController extends Plugin {
    static get requires() {
        return [ Widget ];
    }

    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( "productList", new ProductListCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        /*
         * <productListContainer>
         */
        schema.register( "productListContainer", {
            isObject: true,
            allowWhere: "$block"
        });

        /*
         * <productListProductsContainer>
         */
        schema.register( "productListProductsContainer", {
            isObject: true,
            allowIn: "productListContainer"
        });

        schema.addAttributeCheck( context => {
            if (context.endsWith( "productListProductsContainer" )) {
                return true;
            }
        } );

        /*
         * <productListProduct>
         */
        schema.register( "productListProduct", {
            isObject: true,
            allowIn: "productListProductsContainer",
        });

        schema.addAttributeCheck( context => {
            if (context.endsWith( "productListProduct" )) {
                return true;
            }
        } );

        /*
         * <productListButton>
         */
        schema.register( "productListButton", {
            isObject: true,
            allowIn: "productListContainer",
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        const editor = this.editor;

        /**
         * <productListContainer>
         */
        conversion.for("upcast").elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "productListContainer" );
            },
            view: {
                name: "productlistcontainer",
            }
        } );

        conversion.for("dataDowncast").elementToElement( {
            model: "productListContainer",
            view: {
                name: "productListContainer",
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "productListContainer",
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( "div", { class: `${Constants.selectorPrefix}-product-list-container` } );
                return toWidget( section, viewWriter );
            }
        } );

        /**
         * <productListProductsContainer>
         */
        conversion.for("upcast").elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "productListProductsContainer", {
                    "data-title": viewElement.getAttribute( "data-title" ),
                } );
            },
            view: {
                name: "productlistproductscontainer",
            }
        } );

        conversion.for("dataDowncast").elementToElement( {
            model: "productListProductsContainer",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createContainerElement( "productListProductsContainer", {
                    "data-title": modelElement.getAttribute( "data-title" ),
                } );
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "productListProductsContainer",
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( "div", {
                    class: `${Constants.selectorPrefix}-product-list-products-container`,
                    "data-title": modelElement.getAttribute( "data-title" ),
                } );

                return toWidget( section, viewWriter );
            }
        } );

        /**
         * <productListProduct>
         */
        conversion.for( "upcast" ).elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "productListProduct", {
                    "data-id": viewElement.getAttribute( "data-id" ),
                    "data-mac-title": viewElement.getAttribute( "data-mac-title" ),
                } );
            },
            view: {
                name: "productlistproduct",
            },
        } );

        conversion.for( "dataDowncast" ).elementToElement( {
            model: "productListProduct",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createEmptyElement( "productlistproduct", {
                    "data-id": modelElement.getAttribute( "data-id" ),
                    "data-mac-title": modelElement.getAttribute( "data-mac-title" ),
                } );
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "productListProduct",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createRawElement( "span", {
                    "data-id": modelElement.getAttribute( "data-id" ),
                    "data-mac-title": modelElement.getAttribute( "data-mac-title" ),
                    class: `${Constants.selectorPrefix}-product-list-product${modelElement.getAttribute("class") !== undefined ? ` ${modelElement.getAttribute("class")}` : ""}`
                }, function( domElement ) {
                    domElement.innerHTML = modelElement.getAttribute( "data-mac-title" );
                } );
            }
        } );

        /*
         * <productListButton>
         */
        conversion.for( "upcast" ).elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "productListButton" );
            },
            view: {
                name: "productlistbutton",
            }
        } );

        conversion.for( "dataDowncast" ).elementToElement( {
            model: "productListButton",
            view: {
                name: "productListButton",
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "productListButton",
            view: ( modelElement, { writer: viewWriter } ) => {
                const buttonUIElement = viewWriter.createUIElement( "button", { type: "button" }, function( domDocument ) {
                    const domElement = this.toDomElement( domDocument );

                    const productListProductsContainer = modelElement.previousSibling;

                    domElement.addEventListener("click", () => editor.execute("productList", productListProductsContainer));
                    domElement.innerHTML = editor.config.get("productList.editButtonLabel") || Constants.productList.fallbackEditButtonLabel;

                    return domElement;
                } );
                return buttonUIElement;
            },
        } );
    }
}