import Command from "@ckeditor/ckeditor5-core/src/command";
import { Constants } from "../constants";

export default class InfoImageCommand extends Command {
    execute() {
        const editor = this.editor;

        const onInfoImageSelected = (image) => {
            editor.model.change(writer => {
                const infoImageContainer = writer.createElement("infoImageContainer", { class: `${Constants.selectorPrefix}-info-image` });
                const infoImage = writer.createElement("infoImage", { fileName: image.fileName, "data-asset-url": image.dataAssetURL });
                writer.append( infoImage, infoImageContainer );

                const description = writer.createElement("infoCaption", { class: `${Constants.selectorPrefix}-info-image-description` });
                writer.appendText( editor.config.get("infoImage.descriptionLabel"), description );
                writer.append( description, infoImageContainer );

                const source = writer.createElement("infoCaption", { class: `${Constants.selectorPrefix}-info-image-source` });
                writer.appendText( editor.config.get("infoImage.sourceLabel"), source );
                writer.append( source, infoImageContainer );

                editor.model.insertContent( infoImageContainer, editor.model.document.selection );
            })
        }

        const openCallback = editor.config.get("infoImage.callbackName")

        if (typeof window[openCallback] === "function") {
            window[openCallback](onInfoImageSelected)
        } else {
            console.log("infoImage.callbackName variable not set!")
        }
    }
}