import Command from "@ckeditor/ckeditor5-core/src/command";
import {Constants} from "../constants";

export default class GalleryCommand extends Command {
    /**
     * If galleryImagesContainer is given, then we perform an update on the images: don't recreate existing ones and delete those that were previously in the galleryImagesContainer, but no longer in the callback images
     * @param {*} galleryImagesContainer
     */
    execute(galleryImagesContainer) {
        const editor = this.editor;
        const model = editor.model;

        const onImagesSelected = (images, galleryTitle, isInline) => {
            model.change((writer) => {
                if (galleryImagesContainer === undefined) {
                    this.createGallery(writer, images, galleryTitle, isInline);
                } else {
                    this.updateGallery(writer, galleryImagesContainer, images, galleryTitle, isInline);
                }
            });
        };

        const galleryCallback = editor.config.get("gallery.callbackName");

        if (typeof window[galleryCallback] === "function") {
            const isInline = galleryImagesContainer ? galleryImagesContainer.getAttribute("data-inline") : "false";
            window[galleryCallback](this.getAddedImages(galleryImagesContainer || []), onImagesSelected, isInline);
        } else {
            console.log("gallery.callbackName variable not set!");
        }
    }

    createGallery(writer, images, galleryTitle, isInline) {
        if (!this.isValidImageFound(images)) {
            return;
        }

        const galleryContainer = writer.createElement( "galleryContainer" );

        const galleryImagesContainer = writer.createElement( "galleryImagesContainer", { "data-inline": `${!!isInline }`, "data-title": galleryTitle } );

        for (let index = 0; index < images.length; index++) {
            const imageAttributes = {
                "data-id": images[index].id,
                "data-title": images[index].caption,
                "src": images[index].url,
                "data-source": images[index].credit,
                "data-asset-url": images[index].dataAssetURL,
            };
            const imageElement = writer.createElement("galleryImage", imageAttributes);
            writer.append( imageElement, galleryImagesContainer );
        }

        const galleryButton = writer.createElement( "galleryButton" );
        writer.append( galleryImagesContainer, galleryContainer );
        writer.append( galleryButton, galleryContainer );

        this.editor.model.insertContent( galleryContainer, this.editor.model.document.selection );
    }

    updateGallery(writer, galleryImagesContainer, images, galleryTitle, isInline) {
        writer.setAttribute("data-title", galleryTitle, galleryImagesContainer);
        writer.setAttribute("data-inline", `${!!isInline}`, galleryImagesContainer);

        const addedImages = this.getAddedImages(galleryImagesContainer);

        // Add new images (and ignore images that were already added)
        for (let index = 0; index < images.length; index++) {
            if (addedImages.find(addedImage => addedImage.id === images[index].id)) {
                continue;
            }

            const imageAttributes = {
                "data-id": images[index].id,
                "data-title": images[index].caption,
                "src": images[index].url,
                "data-source": images[index].credit,
                "data-asset-url": images[index].dataAssetURL,
            };
            imageAttributes.class = index > 0 ? `${Constants.selectorPrefix}-display-none` : undefined;

            const imageElement = writer.createElement("galleryImage", imageAttributes);
            writer.append( imageElement, galleryImagesContainer );
        }

        // Remove images (look for those that were already added but are not in the new images)
        const addedIds = addedImages.map(addedImage => addedImage.id);
        const newIds = images.map(image => image.id);
        const removedIds = addedIds.filter(addedId => !newIds.includes(addedId));

        const children = galleryImagesContainer.getChildren();
        let result = children.next();

        const elementsToRemove = [];
        while (!result.done) {
            if (result.value.name === "galleryImage" && removedIds.includes(result.value.getAttribute("data-id"))) {
                elementsToRemove.push(result.value);
            }
            result = children.next();
        }

        elementsToRemove.forEach(element => writer.remove(element));
    }

    isValidImageFound(images) {
        return !!images.find((image) => {
            return typeof image.url === "string" && image.url.length > 0;
        });
    }

    getAddedImages(galleryImagesContainer) {
        if (galleryImagesContainer.length === 0) {
            return;
        }

        const addedImageURLs = [];

        const galleryContainerChildren = galleryImagesContainer.getChildren();
        let result = galleryContainerChildren.next();
        while (!result.done) {
            if (result.value.name === "galleryImage") {
                addedImageURLs.push({
                    id: result.value.getAttribute("data-id"),
                    title: result.value.getAttribute("data-title"),
                    url: result.value.getAttribute("src"),
                });
            }
            result = galleryContainerChildren.next();
        }

        return addedImageURLs;
    }
}
