import Command from "@ckeditor/ckeditor5-core/src/command";
import { Constants } from "../constants";

export default class EmbeddedPostCommand extends Command {
    execute() {
        const editor = this.editor;
        const model = editor.model;

        model.change(writer => {
            const embeddedPostContainer = writer.createElement( "embeddedPostContainer" );

            const onEmbeddedPostSelected = (embeddedPost) => {
                if (typeof embeddedPost.url !== "string" || embeddedPost.url.length === 0) {
                    return;
                }

                model.change(writer => {
                    const selectedId = writer.createElement("embeddedPostSelectedID" );
                    writer.insertText(
                        `${editor.config.get("embeddedPost.selectedIDLabel") || Constants.embeddedPost.fallbackSelectedIDLabel}: ${embeddedPost.title}`,
                        selectedId,
                        "end"
                    );

                    writer.append( selectedId, embeddedPostContainer );

                    const embeddedPostAttributes = {
                        "data-id": embeddedPost.id,
                        "data-title": embeddedPost.title,
                        "data-url": embeddedPost.url,
                    }

                    const embeddedPostElement = writer.createElement("embeddedPost", embeddedPostAttributes);
                    writer.append( embeddedPostElement, embeddedPostContainer );

                    const embeddedPostButton = writer.createElement( "embeddedPostButton" );
                    writer.append( embeddedPostButton, embeddedPostContainer );

                    model.insertContent( embeddedPostContainer, model.document.selection );
                })
            }

            const embeddedPostCallback = editor.config.get("embeddedPost.callbackName")

            if (typeof window[embeddedPostCallback] === "function") {
                window[embeddedPostCallback](getAddedEmbeddedPost(), onEmbeddedPostSelected)
            } else {
                console.log("embeddedPost.callbackName variable not set!")
            }
        });
    }
}

function getAddedEmbeddedPost() {
    const embeddedPostElement = document.querySelector(`.${Constants.selectorPrefix}-embedded-post`);

    return {
        id: embeddedPostElement ? embeddedPostElement.getAttribute("data-id") : "",
        title: embeddedPostElement ? embeddedPostElement.getAttribute("data-title") : "",
        url: embeddedPostElement ? embeddedPostElement.getAttribute("data-url") : "",
    };
}