import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import { toWidget } from "@ckeditor/ckeditor5-widget/src/utils";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import EmbeddedPostCommand from "./EmbeddedPostCommand";
import { Constants } from "../constants";

export default class EmbeddedPostController extends Plugin {
    static get requires() {
        return [ Widget ];
    }

    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( "embeddedPost", new EmbeddedPostCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        /*
         * <embeddedPostButton>
         */
        schema.register( "embeddedPostButton", {
            isObject: true,
            allowIn: "embeddedPostContainer",
            allowContentOf: "$block"
        });

        /*
         * <embeddedPostContainer>
         */
        schema.register( "embeddedPostContainer", {
            isObject: true,
            allowWhere: "$block"
        } );

        /*
         * <embeddedPost>
         */
        schema.register( "embeddedPost", {
            isLimit: true,
            allowIn: "embeddedPostContainer",
            allowWhere: "$block"
        } );

        this.editor.model.schema.addAttributeCheck( context => {
            if (context.endsWith( "embeddedPost" )) {
                return true;
            }
        } );

        /*
         * <embeddedPostSelectedID>
         */
        schema.register( "embeddedPostSelectedID", {
            isObject: true,
            allowIn: "embeddedPostContainer",
            allowContentOf: "$block"
        });

        this.editor.model.schema.addAttributeCheck( context => {
            if (context.endsWith( "embeddedPostSelectedID" )) {
                return true;
            }
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        const editor = this.editor;

        /*
         * <embeddedPostButton>
         */
        conversion.for( "upcast" ).elementToElement( {
            model: "embeddedPostButton",
            view: {
                name: "div",
            }
        } );

        conversion.for( "dataDowncast" ).elementToElement( {
            model: "embeddedPostButton",
            view: {
                name: "div",
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "embeddedPostButton",
            view: ( modelElement, { writer: viewWriter } ) => {
                const buttonUIElement = viewWriter.createUIElement( "button", { type: "button" }, function( domDocument ) {
                    const domElement = this.toDomElement( domDocument );

                    domElement.addEventListener("click", () => editor.execute("embeddedPost"));

                    domElement.innerHTML = editor.config.get("embeddedPost.editButtonLabel") || Constants.embeddedPost.fallbackEditButtonLabel;

                    return domElement;
                } );
                return buttonUIElement;
            },
        } );

        /*
         * <embeddedPostContainer>
         */
        conversion.for("upcast").elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "embeddedPostContainer" );
            },
            view: {
                name: "embeddedpostcontainer",
            }
        } );

        conversion.for("dataDowncast").elementToElement( {
            model: "embeddedPostContainer",
            view: {
                name: "embeddedPostContainer",
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "embeddedPostContainer",
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( "div", { class: `${Constants.selectorPrefix}-embedded-post-container` } );

                return toWidget( section, viewWriter );
            }
        } );

        /**
         * <embeddedPost>
         */
        conversion.for( "upcast" ).elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "embeddedPost", {
                    "data-id": viewElement.getAttribute( "data-id" ),
                    "data-title": viewElement.getAttribute( "data-title" ),
                    "data-url": viewElement.getAttribute( "data-url" ),
                } );
            },
            view: {
                name: "embeddedpost",
            },
        } );

        conversion.for( "dataDowncast" ).elementToElement( {
            model: "embeddedPost",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createEmptyElement( "embeddedpost", {
                    "data-id": modelElement.getAttribute( "data-id" ),
                    "data-title": modelElement.getAttribute( "data-title" ),
                    "data-url": modelElement.getAttribute( "data-url" ),
                } );
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "embeddedPost",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createContainerElement( "div", {
                    "data-id": modelElement.getAttribute( "data-id" ),
                    "data-title": modelElement.getAttribute( "data-title" ),
                    "data-url": modelElement.getAttribute( "data-url" ),
                    class: `${Constants.selectorPrefix}-embedded-post`,
                } );
            }
        } );

        /*
         * <embeddedPostSelectedID>
         */
        conversion.for( "upcast" ).elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "embeddedPostSelectedID" );
            },
            view: {
                name: "embeddedpostselectedid",
            }
        } );

        conversion.for( "dataDowncast" ).elementToElement( {
            model: "embeddedPostSelectedID",
            view: {
                name: "embeddedPostSelectedID",
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "embeddedPostSelectedID",
            view: {
                name: "span",
            }
            // view: ( modelElement, { writer: viewWriter } ) => {
            //     return viewWriter.createContainerElement( "span", {
            //         "data-text": modelElement.getAttribute( "data-text" ),
            //     } );
            // }
            // view: ( modelElement, { writer: viewWriter } ) => {
            //     const span = viewWriter.createUIElement( "div", null, function( domDocument ) {
            //         const domElement = this.toDomElement( domDocument );
            //         domElement.innerHTML = modelElement.getAttribute( "data-text" );
            //         return domElement;
            //     } );
            //     return span;
            // },
        } );
    }
}