import Command from "@ckeditor/ckeditor5-core/src/command";

export default class ProductListCommand extends Command {
    /**
     * If productListProductsContainer is given, then we perform an update on the products: don't recreate existing ones and delete those that were previously in the productListProductsContainer, but no longer in the callback products
     * @param {*} productListProductsContainer
     */
    execute(productListProductsContainer) {
        const editor = this.editor;
        const model = editor.model;

        const onProductsSelected = (products, productListTitle) => {
            model.change((writer) => {
                if (productListProductsContainer === undefined) {
                    this.createProductList(writer, products, productListTitle);
                } else {
                    this.updateProductList(writer, productListProductsContainer, products, productListTitle);
                }
            });
        };

        const productListCallback = editor.config.get("productList.callbackName");

        if (typeof window[productListCallback] === "function") {
            const richTextTitle = productListProductsContainer ? productListProductsContainer.getAttribute("data-title") : undefined;
            window[productListCallback](this.getAddedProducts(productListProductsContainer || []), richTextTitle, onProductsSelected);
        } else {
            console.log("productList.callbackName variable not set!");
        }
    }

    createProductList(writer, products, productListTitle) {
        if (!this.isValidProductFound(products)) {
            return;
        }

        const productListContainer = writer.createElement( "productListContainer" );
        const productListProductsContainer = writer.createElement( "productListProductsContainer", { "data-title": productListTitle } );

        for (let index = 0; index < products.length; index++) {
            const productAttributes = {
                "data-id": products[index].id,
                "data-mac-title": products[index].mac_title,
            };
            const productElement = writer.createElement("productListProduct", productAttributes);
            writer.append( productElement, productListProductsContainer );
        }

        const productListButton = writer.createElement( "productListButton" );
        writer.append( productListProductsContainer, productListContainer );
        writer.append( productListButton, productListContainer );

        this.editor.model.insertContent( productListContainer, this.editor.model.document.selection );
    }

    updateProductList(writer, productListProductsContainer, products, productListTitle) {
        writer.setAttribute("data-title", productListTitle, productListProductsContainer);
        const addedProducts = this.getAddedProducts(productListProductsContainer);

        // Add new products (and ignore products that were already added)
        for (let index = 0; index < products.length; index++) {
            if (addedProducts.find(addedProduct => addedProduct.id === products[index].id)) {
                continue;
            }

            const productAttributes = {
                "data-id": products[index].id,
                "data-mac-title": products[index].mac_title,
            };

            const productElement = writer.createElement("productListProduct", productAttributes);
            writer.append( productElement, productListProductsContainer );
        }

        // Remove products (look for those that were already added but are not in the new products)
        const addedIds = addedProducts.map(addedProduct => addedProduct.id);
        const newIds = products.map(product => product.id);
        const removedIds = addedIds.filter(addedId => !newIds.includes(addedId));

        const children = productListProductsContainer.getChildren();
        let result = children.next();

        const elementsToRemove = [];
        while (!result.done) {
            if (result.value.name === "productListProduct" && removedIds.includes(result.value.getAttribute("data-id"))) {
                elementsToRemove.push(result.value);
            }
            result = children.next();
        }

        elementsToRemove.forEach(element => writer.remove(element));
    }

    isValidProductFound(products) {
        return !!products.find((product) => {
            return typeof product.id === "string" && product.id.length > 0;
        });
    }

    getAddedProducts(productListProductsContainer) {
        if (productListProductsContainer.length === 0) {
            return;
        }

        const addedProductIDs = [];

        const productListContainerChildren = productListProductsContainer.getChildren();
        let result = productListContainerChildren.next();
        while (!result.done) {
            if (result.value.name === "productListProduct") {
                addedProductIDs.push({
                    id: result.value.getAttribute("data-id"),
                    mac_title: result.value.getAttribute("data-mac-title"),
                });
            }
            result = productListContainerChildren.next();
        }

        return addedProductIDs;
    }
}
