import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import { Constants } from "../constants";
import gallery from "../../assets/icons/gallery.svg";

export default class GalleryUI extends Plugin {
    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add( "gallery", locale => {

            const buttonView = new ButtonView( locale );

            buttonView.set( {
                label: editor.config.get("gallery.toolbarLabel" ) || Constants.gallery.fallbackToolbarLabel,
                withText: !editor.config.get("isIconEnabled" ),
                tooltip: !!editor.config.get("isIconEnabled" ),
                icon: editor.config.get("isIconEnabled" ) ? gallery : undefined,
            } );

            this.listenTo( buttonView, "execute", () => editor.execute( "gallery" ) );

            return buttonView;
        } );
    }
}