// import CKEditorInspector from "@ckeditor/ckeditor5-inspector";
import GalleryController from "./GalleryController";
import GalleryUI from "./GalleryUI";
import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import "./styles.css";

export default class Gallery extends Plugin {
    static get requires() {
        return [ GalleryController, GalleryUI ];
    }

    init() {
        // eslint-disable-next-line no-undef
        if (import.meta.env.NODE_ENV === "production") {
            // CKEditorInspector.attach( { "editor": this.editor } );
        }
    }
}