import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import { Constants } from "../constants";

export default class CDNImageUI extends Plugin {
    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add( "cdnImage", locale => {

            const buttonView = new ButtonView( locale );

            buttonView.set( {
                label: editor.config.get("cdnImage.toolbarLabel" ) || Constants.cdnImage.fallbackToolbarLabel,
                withText: true,
                tooltip: false
            } );

            this.listenTo( buttonView, "execute", () => editor.execute( "cdnImage" ) );

            return buttonView;
        } );
    }
}