import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import { Constants } from "../constants";
import embeddedPost from "../../assets/icons/embeddedPost.svg";

export default class EmbeddedPostUI extends Plugin {
    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add( "embeddedPost", locale => {
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                label: editor.config.get("embeddedPost.toolbarLabel" ) || Constants.embeddedPost.fallbackToolbarLabel,
                withText: !editor.config.get("isIconEnabled" ),
                tooltip: !!editor.config.get("isIconEnabled" ),
                icon: editor.config.get("isIconEnabled" ) ? embeddedPost : undefined,
            } );

            this.listenTo( buttonView, "execute", () => editor.execute( "embeddedPost" ) );

            return buttonView;
        } );
    }
}