import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import { toWidget } from "@ckeditor/ckeditor5-widget/src/utils";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import GalleryCommand from "./GalleryCommand";
import { Constants } from "../constants";

export default class GalleryController extends Plugin {
    static get requires() {
        return [ Widget ];
    }

    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( "gallery", new GalleryCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        /*
         * <galleryContainer>
         */
        schema.register( "galleryContainer", {
            isObject: true,
            allowWhere: "$block"
        });

        /*
         * <galleryImagesContainer>
         */
        schema.register( "galleryImagesContainer", {
            isObject: true,
            allowIn: "galleryContainer"
        });

        schema.addAttributeCheck( context => {
            if (context.endsWith( "galleryImagesContainer" )) {
                return true;
            }
        } );

        /*
         * <galleryImage>
         */
        schema.register( "galleryImage", {
            isObject: true,
            allowIn: "galleryImagesContainer",
        });

        schema.addAttributeCheck( context => {
            if (context.endsWith( "galleryImage" )) {
                return true;
            }
        } );

        /*
         * <galleryButton>
         */
        schema.register( "galleryButton", {
            isObject: true,
            allowIn: "galleryContainer",
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        const editor = this.editor;

        /**
         * <galleryContainer>
         */
        conversion.for("upcast").elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "galleryContainer" );
            },
            view: {
                name: "gallerycontainer",
            }
        } );

        conversion.for("dataDowncast").elementToElement( {
            model: "galleryContainer",
            view: {
                name: "galleryContainer",
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "galleryContainer",
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( "div", { class: `${Constants.selectorPrefix}-gallery-container` } );

                return toWidget( section, viewWriter );
            }
        } );


        /**
         * <galleryImagesContainer>
         */
        conversion.for("upcast").elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "galleryImagesContainer", {
                    "data-inline": viewElement.getAttribute( "data-inline" ),
                    "data-title": viewElement.getAttribute( "data-title" ),
                } );
            },
            view: {
                name: "galleryimagescontainer",
            }
        } );

        conversion.for("dataDowncast").elementToElement( {
            model: "galleryImagesContainer",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createContainerElement( "galleryImagesContainer", {
                    "data-inline": modelElement.getAttribute( "data-inline" ),
                    "data-title": modelElement.getAttribute( "data-title" ),
                } );
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "galleryImagesContainer",
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( "div", {
                    class: `${Constants.selectorPrefix}-gallery-images-container`,
                    "data-inline": modelElement.getAttribute( "data-inline" ),
                    "data-title": modelElement.getAttribute( "data-title" ),
                } );

                return toWidget( section, viewWriter );
            }
        } );

        /**
         * <galleryImage>
         */
        conversion.for( "upcast" ).elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "galleryImage", {
                    "data-id": viewElement.getAttribute( "data-id" ),
                    "data-title": viewElement.getAttribute( "data-title" ),
                    src: viewElement.getAttribute( "src" ),
                    "data-source": viewElement.getAttribute( "data-source" ),
                    "data-asset-url": viewElement.getAttribute( "data-asset-url" ),
                } );
            },
            view: {
                name: "galleryimage",
            },
        } );

        conversion.for( "dataDowncast" ).elementToElement( {
            model: "galleryImage",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createEmptyElement( "galleryimage", {
                    "data-id": modelElement.getAttribute( "data-id" ),
                    "data-title": modelElement.getAttribute( "data-title" ),
                    src: modelElement.getAttribute( "src" ),
                    "data-source": modelElement.getAttribute( "data-source" ),
                    "data-asset-url": modelElement.getAttribute( "data-asset-url" ),
                } );
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "galleryImage",
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createContainerElement( "img", {
                    "data-id": modelElement.getAttribute( "data-id" ),
                    "data-title": modelElement.getAttribute( "data-title" ),
                    src: modelElement.getAttribute( "src" ),
                    "data-source": modelElement.getAttribute( "data-source" ),
                    class: `${Constants.selectorPrefix}-gallery-image${modelElement.getAttribute("class") !== undefined ? ` ${modelElement.getAttribute("class")}` : ""}`
                } );
            }
        } );

        /*
         * <galleryButton>
         */
        conversion.for( "upcast" ).elementToElement( {
            model: ( viewElement, conversionApi ) => {
                const modelWriter = conversionApi.writer;
                return modelWriter.createElement( "galleryButton" );
            },
            view: {
                name: "gallerybutton",
            }
        } );

        conversion.for( "dataDowncast" ).elementToElement( {
            model: "galleryButton",
            view: {
                name: "galleryButton",
            }
        } );

        conversion.for( "editingDowncast" ).elementToElement( {
            model: "galleryButton",
            view: ( modelElement, { writer: viewWriter } ) => {
                const buttonUIElement = viewWriter.createUIElement( "button", { type: "button" }, function( domDocument ) {
                    const domElement = this.toDomElement( domDocument );

                    const galleryImagesContainer = modelElement.previousSibling;

                    domElement.addEventListener("click", () => editor.execute("gallery", galleryImagesContainer));
                    domElement.innerHTML = editor.config.get("gallery.editButtonLabel") || Constants.gallery.fallbackEditButtonLabel;

                    return domElement;
                } );
                return buttonUIElement;
            },
        } );
    }
}